var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "main d-flex justify-content-center flex-column my-5",
    },
    [
      _c("img", {
        attrs: {
          src: "https://cdn2.wuwow.tw/pictures/upgrade-test/check.svg",
          alt: "",
        },
      }),
      _vm._m(0),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                to: {
                  name: "InnerPage",
                  params: {
                    startTest: true,
                  },
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("view_explain")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              staticClass: "btn",
              attrs: {
                variant: "primary",
              },
              on: {
                click: _vm.toBack,
              },
            },
            [_vm._v(_vm._s(_vm.$t("return")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "main-text",
      },
      [
        _vm._v("點擊”觀看挑戰說明”，"),
        _c("br"),
        _vm._v("我們將為您解說考前須知，"),
        _c("br"),
        _vm._v("請務必詳細閱讀，以免損失您的權益。"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }